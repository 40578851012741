<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="Update IPD Bill"
                @onClickCloseButton="goToList"
            />
        </div>

        <div class="my-2 px-2">
            <TableHeaderIPDBillAdd
                :formData="formData"
                :patient="patient"
                :tableItems="tableItems"
            />
        </div>

        <div class="col-12 px-2">
            <ListTableUpdate
                :tableItems="tableItems"
                @addNewItem="addNewItem"
                :vatRate="vatRate"
                :salesAccountHeads="salesAccountHeads"
                :isUpdate="true"
            />
        </div>
        <div class="row px-2">
            <div class="col-md-6">
                <div class="mb-1">
                    <label class="form-label" for="description">Ledger Memo</label>
                    <vField
                        as="textarea"
                        name="description"
                        type="number"
                        class="form-control"
                        v-model="formData.description"
                    />
                </div>
            </div>
            <div class="col-md-5 ml-auto mt-auto mb-1">
                <div class="d-flex flex-wrap gap-1 gy-2">
                    <button @click="handleSubmit(false)" class="btn btn-primary">Update</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script setup>
import {inject, onMounted, provide, ref} from 'vue';
import TitleButton from '@/components/atom/TitleButton';
import ListTableUpdate from '@/components/molecule/company/hospital/billing/ipd-bill/InvoiceIPDTableUpdate.vue';
import {useRoute, useRouter} from 'vue-router';
import {generateUUID} from '@/services/utils/global';
import handlePurchase from '@/services/modules/purchase';
import {vatRate} from '@/data/inventory.js';
import handleCompany from "@/services/modules/company";
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import TableHeaderIPDBillAdd from "@/components/molecule/company/hospital/billing/ipd-bill/TableHeaderHospitalBillAdd.vue";
import handleHospital from "@/services/modules/hospital";

const { generatePdf } = hospitalPdfPrinterHelper();
    const { fetchProductList, fetchSalesBill, ...rest } = handlePurchase();
    const { fetchCompanyInfo } = handleCompany();
    const {fetchSingleNote} = handleHospital();

    const $route = useRoute();
    const $router = useRouter();

    const companyId = $route.params.companyId;
    const loader = ref(true);
    const salesAccountHeads = ref([]);
    const totals = ref({});
    const invoiceRes = ref({});
    const company = ref({});
    const patient = ref({});

    let formData = ref({
        company_id: companyId,
        contact_profile_id: null,
        account_head_id: null,
        ipd_register_id: null,
        vat_payable_account_head_id: null,
        mop_account_head_id: null,
        receipt_reference: null,
        receipt_description: "IPD bill payment",
        payment: null,
        project_id: null,
        cost_centre_id: null,
        location_id: null,
        discount_head_id: null,
        adjustment_amount: 0,
        status: 'active',
        bill_number: '',
        money_receipt_no: '',
        date: '',
        has_item_detail: true,
        description: '',
        order_number: null,
        sales_person_id: null,
        commission_percent: null,
        lc_number: null,
        item_details: []
    });
    let tableItems = ref([]);

    provide('formData', formData);
    provide('totals', totals);
    const showSuccess = inject('showSuccess');
    const showError = inject('showError');

    onMounted( async() => {
        const companyQuery = `?company_id=${companyId}`;
        const receiveAbleRes = rest.fetchAccountReceivable(companyQuery);
        const payableRes = rest.fetchAccountPayable(companyQuery);
        const vatPayableRes = rest.fetchVatPayable(companyQuery);
        const fetchCompany = fetchCompanyInfo(companyId);
        const id = $route.params.ipdId;
        const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery);

        Promise.all([
            payableRes.then(res=> {
                if(res.data) formData.value.account_payable_head_id = res.data[0].id;
            }),
            receiveAbleRes.then(res=> {
                if(res.data) formData.value.account_head_id = res.data[0].id;
            }),
            vatPayableRes.then(res=> {
                if(res.data) formData.value.vat_payable_account_head_id = res.data[0].id;
            }),
            fetchCompany.then(res=> {
                if(res.data) company.value = res.data;
            }),
            salesAccountHeadsRes.then(res => {
                if(res.data) salesAccountHeads.value = res.data
            }),
            fetchSingleNote(companyQuery, $route.params.ipdRegisterId).then(res => {
                if (res.data) {
                    patient.value = res.data;
                    formData.value.contact_profile_id = res.data.patient.id
                    formData.value.ipd_register_id = res.data.id
                    formData.value.service_resource_id = res.data.human_resource_id
                }
            }),
            fetchSalesBill(id, companyQuery).then( (res) =>{
              if( res ){
                  const { receipt_generals } = res.data
                  formData.value = {
                      company_id: res.data.company_id,
                      contact_profile_id: res.data.contact_profile_id,
                      account_head_id: res.data.account_head_id,
                      ipd_register_id: res.data.ipd_register_id,
                      vat_payable_account_head_id: res.data.vat_payable_account_head_id,
                      receipt_description: "IPD bill payment",
                      project_id: res.data.project_id,
                      cost_centre_id: res.data.cost_centre_id,
                      location_id: res.data.location_id,
                      discount_head_id: res.data.discount_head_id,
                      adjustment_amount: res.data.adjustment_amount,
                      status: 'active',
                      bill_number: res.data.bill_number,
                      date: res.data.date,
                      has_item_detail: res.data.has_item_detail,
                      description: res.data.description,
                      order_number: res.data.order_number,
                      sales_person_id: res.data.sales_person_id,
                      commission_percent: res.data.commission_percent,
                      lc_number: res.data.lc_number,
                      item_details: res.data.general
                  };
                  tableItems.value = res.data.general.map(({product, ...item}) => {
                      return {
                          ...item,
                          name: product.name,
                          product: {
                              name: product.name
                          },
                      }
                  })

                  if (receipt_generals && receipt_generals[0]) {
                      formData.value.money_receipt_no = receipt_generals[0].receipt_master.voucher_no
                      formData.value.mop_account_head_id = receipt_generals[0].receipt_master.account_head_id
                      formData.value.receipt_master_id = receipt_generals[0].receipt_master.id;
                      formData.value.receipt_reference = receipt_generals[0].receipt_master.receipt_reference
                      formData.value.payment = receipt_generals[0].receipt_master.total_paid_amount
                  }
              }
            }).catch( (err) => {
              console.log(err);
            })
        ])
        .then(() => {loader.value=false})
        .catch(()=>{loader.value=false})
    })
    const addNewItem = () => {

        tableItems.push({
            id: generateUUID(),
            product_id: res.data.product_id,
            name: '',
            description: '',
            quantity : 1,
            rate: 0,
            discount_percent: 0,
            vat: 15,
            sub_total: 0,
            discount_amount: 0,
            vat_amount: 0,
            total: 0,
            isEdit: false,
        });

    }

    const goToList = () => {
        $router.push({name: 'ipd-bill-list',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId
            },
            query: {
                start: $route.query.start,
                end: $route.query.end
            }

        })
    }

    const handleSubmit = (print=false) => {
        if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0];
        formData.value.item_details = tableItems;
        let copyFormData = JSON.parse(JSON.stringify(formData.value))
        copyFormData.item_details = formData.value.item_details
        copyFormData.sale_type = "ipd_invoice"
        copyFormData.receipt_type = "ipd_invoice"

        rest.updateSalesBill($route.params.ipdId, copyFormData)
        .then(res => {  
            if(res.status) {
                showSuccess(res.message)
                if(print) goToPrint(res.data.id)
                goToList();
                return;
            }
            showError(res.message)
        })
        .catch(err=> {
            showError(err.response.data.message)
        })
    }

    const goToPrint = (id) => {
        const companyQuery = `?company_id=${companyId}`;

        fetchSalesBill(id, companyQuery).then( (res) =>{
            if( res ){
                invoiceRes.value = res.data;
            }
        }).then( () => {
            generatePdf(company.value, invoiceRes.value, 'BILL', true, true)
        }).catch( (err) => {
            console.log(err);
        })
    }

</script>
<style scoped>
address{
    margin-bottom: 0 !important;
}
</style>